// compontns
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import PageTitle from '../components/atoms/PageTitle';
import NewsList from '../components/molecules/NewsList';

// other
import React from 'react';
import styled from 'styled-components';
import { NewsPc, NewsSp, CROCCHA_API, MetaDesc, MetaOGP } from '../utils';
import { breakpoint } from '../components/theme';
import useMedia from '../customHooks/userMedia';
import HeadBase from '../components/molecules/HeadBase';

const NewsPage = () => {
  const isMobile = useMedia('(max-width: 767px)');
  return (
    <BaseLayoutTemplate>
      <HeadBase
        metas={{
          title: 'ニュース | tryangle株式会社',
          description: MetaDesc,
          openGraph: {
            type: 'article',
            url: `${CROCCHA_API.SELF_DOMAIN}/news/`,
            title: 'ニュース | tryangle株式会社',
            description: MetaDesc,
            images: [
              {
                url: MetaOGP,
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />

      {/* アイキャッチ */}
      <PageTitle
        url={isMobile ? NewsSp : NewsPc}
        title='News'
        span='ニュース'
      />
      <Section>
        {/* ニュース */}
        <NewsList />
      </Section>
    </BaseLayoutTemplate>
  );
};

export default NewsPage;

const Section = styled.section`
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 800px;
  @media ${breakpoint.sp} {
    padding: 0 3rem;
  }
`;
