// compontns
import NewsCard from '../../atoms/NewsCard';
import List from '../../atoms/List';

// other
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { breakpoint } from '../../theme';
import { dummyNewses } from 'app/src/utils/dummy';

const NewsList = () => {
  return (
    <StaticQuery
      query={graphql`
        query NewsListQuery {
          allContentfulNews(
            limit: 999
            filter: { releaseDate: { ne: null } }
            sort: { fields: [releaseDate], order: DESC }
          ) {
            edges {
              node {
                newsId
                title
                type
                releaseDate(formatString: "YYYY/MM/DD")
              }
            }
          }
        }
      `}
      render={({ allContentfulNews }) => {
        const newses = allContentfulNews.edges;

        return (
          <NewsWrapList
            dataSource={newses} // {dummyNewses}
            renderRow={(obj, index) => {
              const { newsId, title, type, releaseDate } = obj.node;
              return (
                <NewsListItem key={index}>
                  <NewsCard
                    category={type}
                    date={releaseDate}
                    title={title}
                    to={`news/${newsId}`}
                  />
                </NewsListItem>
              );
            }}
            empty={null}
          />
        );
      }}
    />
  );
};

export default NewsList;

const NewsWrapList = styled(List)`
  padding: 3rem 0;
  display: flex;
  flex-wrap: wrap;
  @media ${breakpoint.sp} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const NewsListItem = styled.li`
  margin-bottom: 34px;
  @media ${breakpoint.sp} {
    width: 100%;
    max-width: 230px;
  }
  @media ${breakpoint.tab} {
    width: calc((100% - 2rem) / 3);
    :nth-child(3n-1) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
`;
